import { getWeb3Obj } from "src/utils";

export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 97;
export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
export const dataFormat = "DD MMM YYYY hh:mm a ";
export const stakingContractAddress =
  "0xFD5C6e40a4E4fdCB4458A30DA474070b618DBb74";
export const marketPlaceAddress = "0xC6EC655dF149C83b5428E58497Ef4f13014b6Bd2"; //mainnet-client-price
export const hexaSaleAddress = "0x467F24eF4d442B917087E51FE2110E4893E7ede7"; //mainnet-client-price
export const dateFormat = "DD/MM/YYYY";
export const nativeTokenAddressHexa =
  "0xaf48F49c006AD5E097B9b07A190746ccE58eF1d3"; //mainnet-client-price

export const NetworkDetails = [
  {
    chainId: "0x61",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
];

export const getWeb3ContractObject = async (
  abi,
  contractAddress,
  RPC_URL = RPC_URL
) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export const validAddUrl = (value) => {
  const re =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return re.test(value);
};
