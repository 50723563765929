import React, { useContext } from "react";
import SettingsContext from "src/context/SettingsContext";

const Logo = (props) => {
  const themeSeeting = useContext(SettingsContext);

  return (
    <>
      <div>
        <img
          className="mobileShow"
          src="/images/Mobiloitte_blue_logo.png"
          alt="Logo"
          width="100%"
          {...props}
        />
      </div>
      <div>
        {themeSeeting.settings.theme === "DARK" ? (
          <img
            className="mobileHide"
            src="/images/Mobiloitte_blue_logo.png"
            width="100%"
            alt="Logo"
            {...props}
          />
        ) : (
          <img
            className="mobileHide"
            src="/images/Mobiloitte_blue_logo.png"
            width="100%"
            alt="Logo"
            {...props}
          />
        )}
      </div>
    </>
  );
};

export default Logo;
