import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiMenu: {
      list: {
        background: "#06010d",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#808080c2 !important",
      },
    },
    MuiSelect: {
      icon: {
        top: "calc(50% - 12px)",
        color: "#fff",
        right: "0",
        position: "absolute",
        pointerEvents: "none",
      },
    },
    MuiDropzoneArea: {
      text: {
        fontSize: "14px",
        fontWeight: "300",
      },
      root: {
        width: "100%",
        border: "dashed",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
        boxSizing: "border-box",
        minHeight: "140px",
        borderColor: "rgba(255, 255, 255, 0.12)",
        borderRadius: "4px",
        backgroundColor: "transparent",
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: " #f5f5f5",
      },
      toolbarTxt: {
        color: "#f5f5f5",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        zIndex: 1,
        backgroundColor: "none",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        height: "100px",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#171425",
      },
    },
    MuiPaginationItem: {
      "& page": {
        "& .Mui-selected": {
          backgroundColor: "red !imporatant",
          boxShadow:
            "0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733 !imporatant",
        },
      },

      outlined: {
        border: "1px solid rgb(255 255 255 / 50%)",
      },
      rounded: {
        "& .aria-label": {
          background: "#B51632",
          boxShadow: " 0px 10px 20px -14px rgb(204 35 75 / 40%)",
          borderRadius: "8px",
        },
      },
    },
    MuiTableCell: {
      head: {
        color: "rgba(255, 255, 255, 0.5)",
        fontWeight: "500",
        lineHeight: "1.5rem",
      },
      root: {
        display: "table-cell",
        padding: "8px 8px",
        fontSize: "14px",
        textAlign: "left",
        fontFamily: "'Inter', sans-serif",
        fontWeight: "300",
        lineHeight: "1.43",
        borderBottom: "none",
        verticalAlign: "inherit",
      },
    },
    MuiTableHead: {
      root: {
        background: "rgba(0, 0, 0, 0.2)",
        height: "50px",
      },
    },
    MuiDivider: {
      root: {
        border: "none",
        height: "1px",
        margin: 0,
        flexShrink: 0,
        background: "rgba(255, 255, 255, 0.03)",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
    MuiInputBase: {
      input: {
        color: "#fff",
        fontWeight: "200",
        fontSize: "14px",
        height: "30px !important",
        "&:-webkit-autofill": {
          WebkitBackgroundClip: "text !important",
          WebkitTextFillColor: "#D9D9D9 !important",
        },
      },
      root: {
        "& .Mui-disabled": {
          color: "rgb(255 255 255) !important",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        borderRadius: "10px",
        padding: "13.5px 12px",
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text !important",
          // transitionDelay: "9999s",
          "caret-color": "transparent",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
          "-webkit-text-fill-color": "#fff",
        },
        "&:-internal-autofill-selected": {
          color: "#fff",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: "#FFFFFF",
        fontWeight: "500",
        backgroundColor: "transparent",
        padding: "6px 19px",
        height: "45px",
        "&:hover": {
          backgroundColor: "#573196",

          padding: "6px 19px",
        },
      },
      contained: {
        background: "#573196",
        boxShadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 8px #573196, inset 0px 0px 35px #573196",
        borderRadius: "50px",
        fontSize: "16px",
        fontWeight: "300",
        "@media(max-width:767px)": {
          fontSize: "12px",
        },
        "&.Mui-disabled": {
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 8px #b617339c, inset 0px 0px 35px #b5163208",
          color: "#757477",
        },
      },
      outlinedPrimary: {
        color: "#fff",
        padding: "6px 19px",
        "&:hover": {
          background: "#573196",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 8px #573196, inset 0px 0px 35px #573196",
          border: "1px solid #573196",
          padding: "6px 19px",
        },
      },

      "& .Mui-disabled": {
        color: "#fafafa78 !important",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "1px solid #C5C5C5",
        },
        "&::after": {
          borderBottom: "1px solid #C5C5C5 !important",
        },
      },
    },
    MuiPaper: {
      elevation0: {
        backgroundColor: "#171425",
      },
      elevation1: {
        padding: "10px",
        borderRadius: "10px",
        background: "rgba(255, 255, 255, 0.03)",
      },
    },
    MuiDialog: {
      paper: {
        boxShadow:
          "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff,1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47),  -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
        backdropFilter: "blur(30px)",
        borderRadius: "20px",
        // borderRadius: "10px",
        background: "#06010D",
        overflow: "hidden",
        overflowY: "none",
        "@media(max-width:768px)": {
          backdropFilter: "none !important",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      // paper: colors.common.white,
    },
    primary: {
      main: "#FFFFFF",
      dark: "#de0d0d",
      light: "#9C162E",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#FFFFFF",
      // import { softShadows, strongShadows } from "./shadows";
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
